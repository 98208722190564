import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import SubAdminDefaultLayout from '@/layouts/SubAdminDefaultLayout'
import SuperDefaultLayout from '@/layouts/SuperDefaultLayout'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/base/Login'),
    render() {
      return h(resolveComponent('router-view'))
    },
  },
  {
    path: '/error',
    name: 'Page404',
    component: () => import('@/views/base/Page404.vue'),
  },
  // {
  //   path: '/base/VerifyAdminEmail',
  //   name: 'VerifyAdminEmail',
  //   component: () => import('@/views/base/VerifyAdminEmail.vue'),
  // },
  //Admin
  {
    path: '/',
    name: 'DefaultLayout',
    component: DefaultLayout,
    redirect: '/base/organisation',
    children: [
      {
        path: '/base/coach',
        name: 'Coach',
        component: () => import('@/views/base/Coach.vue'),
      },
     
      {
        path: '/base/organisation',
        name: 'Organization',
        component: () => import('@/views/base/Organization.vue'),
      },
      {
        path: '/base/user',
        name: 'User',
        component: () => import('@/views/base/User.vue'),
      },
      
      {
        path: '/base/organisation/user/:admin_id',
        name: 'OrganizationUser',
        component: () => import('@/views/base/OrganizationUser.vue'),
      },
      {
        path: '/base/course',
        name: 'Course',
        component: () => import('@/views/base/Course.vue'),
      },
      {
        path: '/courseView/EditAllCourseDetails/:courseId',
        name: 'EditAllCourseDetails',
        component: () => import('@/views/courseView/EditAllCourseDetails'),
      },
      // {
      //   path: '/base/course/view',
      //   name: 'ViewCourseDetails',
      //   component: () => import('@/views/base/ViewCourseDetails.vue'),
      // },
      {
        path: '/base/category',
        name: 'Category',
        component: () => import('@/views/base/Category.vue'),
      },
      {
        path: '/base/tag',
        name: 'Tag',
        component: () => import('@/views/base/Tag.vue'),
      },
      {
        path: '/base/audio',
        name: 'Audio',
        component: () => import('@/views/base/Audio.vue'),
      },
      {
        path: '/base/SendMessage',
        name: 'Send Message',
        component: () => import('@/views/base/SendMessage.vue'),
      },
      {
        path: '/base/quotes',
        name: 'Quotes',
        component: () => import('@/views/base/quotes.vue'),
      },
      {
        path: '/base/Feedback',
        name: 'Feedback',
        component: () => import('@/views/base/Feedback.vue'),
      },
      {
        path: '/base/Admin',
        name: 'Admin',
        component: () => import('@/views/base/Admin.vue'),
      },
      {
        path: '/base/SuperAdmin',
        name: 'Super Admin',
        component: () => import('@/views/base/SuperAdmin.vue'),
      },
    
    ],
  },


  // superDefault
  {
    path: '/',
    name: 'SuperDefaultLayout',
    component: SuperDefaultLayout,
    redirect: '/base/organisation',
    children: [
      {
        path: '/base/coach',
        name: 'Coach',
        component: () => import('@/views/base/Coach.vue'),
      },
     
      {
        path: '/base/organisation',
        name: 'Organization',
        component: () => import('@/views/base/Organization.vue'),
      },
      {
        path: '/base/user',
        name: 'User',
        component: () => import('@/views/base/User.vue'),
      },
      
      {
        path: '/base/organisation/user/:admin_id',
        name: 'OrganizationUser',
        component: () => import('@/views/base/OrganizationUser.vue'),
      },
      {
        path: '/base/course',
        name: 'Course',
        component: () => import('@/views/base/Course.vue'),
      },
      {
        path: '/courseView/EditAllCourseDetails/:courseId',
        name: 'EditAllCourseDetails',
        component: () => import('@/views/courseView/EditAllCourseDetails'),
      },
      // {
      //   path: '/base/course/view',
      //   name: 'ViewCourseDetails',
      //   component: () => import('@/views/base/ViewCourseDetails.vue'),
      // },
      {
        path: '/base/category',
        name: 'Category',
        component: () => import('@/views/base/Category.vue'),
      },
      {
        path: '/base/tag',
        name: 'Tag',
        component: () => import('@/views/base/Tag.vue'),
      },
      {
        path: '/base/audio',
        name: 'Audio',
        component: () => import('@/views/base/Audio.vue'),
      },
      {
        path: '/base/SendMessage',
        name: 'Send Message',
        component: () => import('@/views/base/SendMessage.vue'),
      },
      {
        path: '/base/quotes',
        name: 'Quotes',
        component: () => import('@/views/base/quotes.vue'),
      },
      {
        path: '/base/Feedback',
        name: 'Feedback',
        component: () => import('@/views/base/Feedback.vue'),
      },
      // {
      //   path: '/base/Admin',
      //   name: 'Admin',
      //   component: () => import('@/views/base/Admin.vue'),
      // },
    
    ],
  },


  // SubAdminDefaultLayout
  {
    path: '/',
    name: 'SubAdminDefaultLayout',
    component: SubAdminDefaultLayout,
    redirect: '/organisation/users',
    children: [
      {
        path: '/organisation/users',
        name: 'Users',
        component: () => import('@/views/base/OrganizationUser.vue'),
      },
    ]
  },
  
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
