<template>
<CSidebar class="p-3 mb-2 text-white sideColor " position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    ">
     <CSidebarBrand class="pb-5 text-white">
     
        <img src="@/assets/images/avatars/111.png" class="" width="80 " height="70"/>
    </CSidebarBrand>
    <AppSidebarNav />
    <!-- <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" /> -->
</CSidebar>
</template>

<script>
import {
    computed
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    AppSidebarNav
} from './AppSidebarNav'
import {
    logoNegative
} from '@/assets/brand/logo-negative'
import {
    sygnet
} from '@/assets/brand/sygnet'
export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    setup() {
        const store = useStore()
        return {
            logoNegative,
            sygnet,
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
        }
    },
}
</script>
<style>
.sideColor{
    background: linear-gradient(45deg, rgb(195 15 127) 0%, rgb(84, 165, 221) 100%) !important;
}
.sidebar-brand{
    background: transparent;
}
</style>