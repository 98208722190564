<template>
<CModalHeader class="sideColor text-white">
    <CModalTitle>Admin Profile</CModalTitle>
</CModalHeader>
<CModalBody>
    <div class="row">
        <div class="col-md-12">
            <CButton class="float-end btn" style="padding: 0; border: none;  color: blue; text-decoration: underline;" @click="() => { visibleLiveDemo = true }">Change Password</CButton>
        </div>
    </div>
    <CForm class="row g-4 fw-bold float-end">
        <CCol md="12">

            <div class="profile-pic-wrapper">
                <div class="pic-holder">

                    <img v-if="itemData.profile_image_url" id="profilePic" class="pic" :src="itemData.profile_image_url">
                    <img v-else id="profilePic" class="pic" src='@/assets/images/testimage.png'>
                    <img :src="croppedImage" class="pic" />

                    <Input type="file" id="newProfilePhoto" accept="image/*" style="opacity: 0;" @change="setImage" for="profilePic" required />
                    <label for="newProfilePhoto" class="upload-file-block">
                        <div class="text-center">
                            <div class="mb-2">
                                <i class="fa fa-camera fa-2x"></i>
                            </div>
                            <div class="text-uppercase">
                                Change <br /> Profile Photo
                            </div>
                        </div>
                    </label>
                </div>

            </div>

            <div v-if="cropp" class=" p-3 mb-2 bg-white shadow rounded-lg">
                <form class=" d-flex align-items-center justify-content-center mx-auto" @submit.prevent="cropImage">
                    <vue-cropper class="mr-2" ref="cropper" :guides="true" :src="imageSrc"></vue-cropper>

                    <button style="margin-left:10px">Crop</button>
                </form>

            </div>

            <!-- 
                    <div class="profile-pic-wrapper" v-for="item in list" v-bind:key="item.admin_id">
                        <div v-if="item.profile_image_url">
                            <img id="profilePic" class="pic" :src="item.profile_image_url">
                        </div>
                        <div v-else class="pic-holder">
                            <img id="profilePic" class="pic" src='@/assets/images/testimage.png'>
                        </div>
                    </div> -->
        </CCol>

        <CCol md="12">
            <CFormInput feedbackValid="Looks good!"  id="validationDefault02" label="Name" v-model="itemData.name" required disabled />
        </CCol>
        <CCol md="12">
            <CFormInput feedbackValid="Looks good!" id="validationDefault03" label="Email Id" v-model="itemData.email_id" required disabled />
        </CCol>
        <!-- <CCol md="12">
                    <CFormInput feedbackValid="Looks good!" id="validationDefault04" label="Color Codes" required />
                </CCol> -->
    </CForm>
    <CModalFooter>
        <CButton v-if="loading">
            <CSpinner color="primary" component="span" size="sm" aria-hidden="true" />
            Loading...
        </CButton>
        <CButton v-else class="btn sideColor text-white" type="submit" v-on:click="updateProfile()">Save changes</CButton>&nbsp;
        <CButton class="sideColor text-white" v-on:click="closePage()">
            Close
        </CButton>
    </CModalFooter>
</CModalBody>
<!--Change Admin Password -->
<CModal size='sm' backdrop="static" alignment="center" :visible="visibleLiveDemo" @close="() => { visibleLiveDemo = false }">
    <CModalHeader class="sideColor">
        <CModalTitle class="text-white">Change Password</CModalTitle>
    </CModalHeader>
    <CModalBody style="background-color: #d8dbe0;">
        <CForm class="mb-1">
            <CFormLabel for="inputPassword" class="col-form-label fw-bold">New Password<span style="color: #FF0000;">*</span></CFormLabel>
            <CFormInput type="password" id="password" name="password" placeholder="Enter new password" v-model="password" :maxlength="max"  required />

            <CFormLabel for="inputPassword" class="col-form-label fw-bold"> Confirm New Password<span style="color: #FF0000;">*</span></CFormLabel>
            <CFormInput type="password" id="confirmPassword" name="confirmPassword" placeholder="Enter confirm new Password" v-model="confirmPassword" :maxlength="max" required />
            <!-- <CRow class="mb-1 text-center">
                <div v-if="message =='Password not matched'" class=" text-center" style="color: red;">{{ message }}</div>
                <div v-else-if="message =='Password not reset'" class=" text-center" style="color: red;">{{ message }}</div>
                <div v-else class="text-center" style="color: red;">{{ message }}</div>
            </CRow> -->
            <hr />
            <div class="mb-2">
                <CButton class="sideColor float-end text-white" color="dark" @click="() => { visibleLiveDemo = false }" v-on:click="resetPassword()">Update Password</CButton>
            </div>
        </CForm>
    </CModalBody>
</CModal>
</template>

<script>
import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"
import axios from 'axios';
export default {
    name: 'AdminProfile',
    props: ["adminId"],
    components: {
        VueCropper,
    },
    data() {
        return {
            max: 8,
            list: [],
            visibleLiveDemo: false,
            cropp: false,
            imageSrc: "",
            croppedImage: '',
            loading: false,
            admin_change_image: '',
            is_change: '',
            itemData: {
                admin_id: '',
                name: '',
                email_id: '',
                profile_image_url: ''
            },
            message: '',
            password: '',
            confirmPassword: ''
        }

    },

    methods: {
        async resetPassword() {
            const strongPassRegEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",);
            if (this.password.length < 8) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Password must be at least 8 characters",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                });
            } else if (this.password.length > 8) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Password have more than 8 characters",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                });
            } else if (this.password.search(/[a-z]/i) < 0) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Atleast one alphabet required",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                });
            } else if (this.password.search(/[0-9]/) < 0) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Atleast one number required",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                })
            } else if (this.password.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) < 0) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Atleast one special character required",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                });
            } else if(!strongPassRegEx.test(this.password)) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Atleast one upper case letter required",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                });
            } else if (this.password != this.confirmPassword) {
                this.visibleLiveDemo = true;
                swal({
                    title: 'Failed',
                    text: "Confirm password not matched",
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                });
            } else if (this.password) {
                const Headers = {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": JSON.parse(localStorage.getItem('admin_id'))
                }
                const data = {
                    password: this.password
                }
                let response = await axios.post("change-password", data, {
                    headers: Headers
                });
                // console.log(response);
                if (response.data.status == '1') {
                    // this.message = response.data.message;
                    swal({
                        title: 'success',
                        text: "Admin password changed successfully",
                        icon: 'success',
                        buttons: false,
                        timer: 2000,
                    })
                    // setTimeout(() => {
                    //     this.$router.go();
                    // }, 2000);
                } else {
                    swal({
                        title: 'Failed',
                        text: "Admin password not changed",
                        icon: 'error',
                        buttons: false,
                        timer: 2000,
                    })
                }

            } else {
                this.loading = false;
                swal({
                    title: 'Failed',
                    text: 'Fill the Field',
                    icon: 'error',
                    buttons: false,
                    timer: 2000,
                })
            }

        },

        async updateProfile() {
            if (this.is_change == '1') {
                this.loading = true
                console.log("this.admin_change_image", this.admin_change_image);
                setTimeout(async () => {
                    const formData = new FormData();

                    formData.append('profile_image_url', this.admin_change_image)
                    formData.append('admin_id', this.itemData.admin_id)
                    formData.append('is_change', this.is_change)

                    const Headers = {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": JSON.parse(localStorage.getItem('admin_id'))
                    };

                    let result = await axios.post("edit-admin-profile", formData, {
                        headers: Headers
                    });
                    if (result.data.status == "1") {
                        swal({
                            title: 'Status',
                            text: 'Successfully updated',
                            icon: 'success',
                            buttons: false,
                            timer: 2000,
                        })
                        this.loading = false,
                            setTimeout(() => {
                                this.$router.go()
                            }, 2000)

                    } else {
                        this.loading = false,
                            alert("Somthing went wrong please try again")
                    }

                }, 2000)

            } else {

                alert("Please change image first")

            }

            // const Headers = {
            //     "Content-type": "application/json; charset=UTF-8",
            //     "Authorization": JSON.parse(localStorage.getItem('admin_id'))
            // };

            // let result = await axios.post("http://localhost:5002/edit-admin-profile", {
            //     admin_id: this..admin_id,
            //     name: this.itemData.name,
            //     email_id: this.itemData.email_id
            // }, {
            //     headers: Headers,
            // });
            // alert('Successfully updated')
            // this.$router.go(),
            //     console.log("update data", result);
            // this.$router.go()

        },

        setImage(event) {
            // document.getElementById("editCoach").style.display = "none";
            const file = event.target.files[0]
            if (file.size <= 1024 * 1024 * 3) {
                if (!file.type.includes("image/")) {
                    alert("Please select an image file")
                    return
                }
                if (typeof FileReader === "function") {
                    const reader = new FileReader()
                    reader.onload = event => {
                        this.imageSrc = event.target.result
                        this.cropp = true
                        // Rebuild cropperjs with the updated source
                        this.$refs.cropper.replace(event.target.result)

                    }
                    reader.readAsDataURL(file)

                } else {
                    alert("Sorry, FileReader API not supported")
                }
            } else {
                swal({
                    title: 'error',
                    text: "Maximum allowed file size is 3 MB",
                    icon: 'error',
                    buttons: false,
                    timer: 3000,
                })

            }

        },
        async cropImage() {

            // Get image data for post processing, e.g. upload or setting image src
            this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', (20 / 100))
            await this.uploadImage();
            // document.getElementById("editCoach").style.display = "";
        },
        async uploadImage() {
            await this.$refs.cropper.getCroppedCanvas({
                minWidth: 256,
                minHeight: 256,
                maxWidth: 1000,
                maxHeight: 1000,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            }).toBlob((blob) => {
                this.admin_change_image = blob
                console.log("this.admin_change_image", this.admin_change_image);
                this.is_change = '1'
                this.cropp = false

            });

        },
        closePage() {

            this.$router.go()

        },

    },

    async mounted() {
        //for clear image file
        $(document).ready(function () {
            $('#newProfilePhoto').on('click', function (e) {
                var $el = $('#newProfilePhoto');
                $el.wrap('<form>').closest(
                    'form').get(0).reset();
                $el.unwrap();
            });
        });

        const Headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": JSON.parse(localStorage.getItem('admin_id'))
        }

        let result = await axios.post("adm-details-byId", this.adminId, {
            headers: Headers
        });
        this.itemData = result.data.Data[0];
        // this.list = result.data.Data

        // console.warn(result)
        // console.log("gs", this.list);
    }

}
</script>

<style scoped>
.profile-pic-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 7px;

}

.profile-pic-wrapper .pic-holder {
    text-align: center;
    position: relative;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.pic-holder .pic {
    position: absolute;
    height: 100%;
    width: 100%;
    -o-object-fit: fill;
    object-fit: fill;
    -o-object-position: center;
    object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(90, 92, 105, 0.7);
    color: #f8f9fc;
    font-size: 12px;
    font-weight: 600;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.pic-holder .upload-file-block {
    cursor: pointer;
}

.pic-holder:hover .upload-file-block,
.uploadProfileInput:focus~.upload-file-block {
    opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
    display: none;
}

.pic-holder.uploadInProgress .upload-loader {
    opacity: 1;
}

.snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 14px;
    transform: translateX(-50%);
}

.snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.textAreaCont .lead {
    position: absolute;
    bottom: 0;
    right: 15px;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
}

.textAreaCont .short {
    position: absolute;
    bottom: 0;
    right: 15px;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
}
</style>
