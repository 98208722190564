<template>
<CDropdown :color="item" :togglerText="item" variant="btn-group">
    <CDropdownToggle class="dropdown-item fw-bold p-2" :color="item">{{adminName}}</CDropdownToggle>&nbsp;&nbsp;
    <CDropdownMenu>

        <CDropdownItem>

            <CButton class="dropdown-item fw-bold" @click="() => { visibleLiveDemo = true }">
                <i class="fa fa-user" aria-hidden="true"></i> Profile</CButton>

        </CDropdownItem>

    </CDropdownMenu>
</CDropdown>
&nbsp;&nbsp;
<CAvatar>
    <div v-if="profile_image_url">
        <img :src="profile_image_url" class="rounded-circle" width="40 " height="40" />
    </div>
    <div v-else>
        <img src='@/assets/images/testimage.png' class="rounded-circle" width="40 " height="40" />
    </div>
    </CAvatar>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

<!-- edit admin profile -->
<CModal :visible="visibleLiveDemo" backdrop="static" @close="() => { visibleLiveDemo = false }">
    <AdminProfile :adminId="adminId" />
</CModal>
</template>

<script>
import avatar from '@/assets/images/testimage.png'
import AdminProfile from './AdminProfile.vue'
import axios from 'axios';
export default {
    name: 'AppHeaderDropdownAccnt',
    components: {
        AdminProfile
    },
    data() {
        return {
            avatar: avatar,
            itemsCount: 42,
            visibleLiveDemo: false,
            list: [],
            adminName: '',
            profile_image_url: '',
            adminId: ''
        }
    },
    async mounted() {

        const body = {
            admin_id: JSON.parse(localStorage.getItem('admin_id'))
        }
        const Headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": JSON.parse(localStorage.getItem('admin_id'))
        }
        //console.warn("Admin_id ... ", body)
        let result = await axios.post('adm-details-byId', body, {
            headers: Headers
        })
        //console.log("resultdata", result)
        this.list = result.data.Data
        this.adminName = this.list[0].name
        this.profile_image_url = this.list[0].profile_image_url
        this.adminId = body

        //console.log('this  list', this.adminId)
    }
}
</script>
