export default [
  {
    component: 'CNavItem',
    name: 'Organisation',
    icon: 'cilSitemap',
    to: '/base/organisation',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'VerifyAdminEmail',
  //   icon: 'cilPeople',
  //   to: '/base/VerifyAdminEmail',
      
  // },
  {
    component: 'CNavItem',
    name: 'User',
    icon: 'cilPeople',
    to: '/base/user',
  },
 
  {
    component: 'CNavItem',
    icon: 'cilClipboard',
    name: 'Coach',
    to: '/base/coach',
  },
  {
    component: 'CNavItem',
    name: 'Course',
    icon: 'cilEducation',
    to: '/base/course',
  },
  {
    component: 'CNavItem',
    name: 'Category',
    icon: 'cilList',
    to: '/base/category',
  },
  {
    component: 'CNavItem',
    name: 'Tag',
    icon: 'cilTag',
    to: '/base/tag',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Audio',
  //   icon: 'cilAudioDescription',
  //   to: '/base/audio',
  // },
  {
    component: 'CNavItem',
    name: 'Send Message',
    icon: 'cilSpeech',
    to: '/base/SendMessage',
  },
  {
    component: 'CNavItem',
    name: 'Quotes',
    icon: 'cilDoubleQuoteSansLeft',
    to: '/base/quotes',
  },
  {
    component: 'CNavItem',
    name: 'Feedback',
    icon: 'cilColorBorder',
    to: '/base/Feedback',
      
  },
  {
    component: 'CNavItem',
    name: 'Admin',
    icon: 'cilUser',
    to: '/base/Admin',
      
  },
  {
    component: 'CNavItem',
    name: 'Super Admin',
    icon: 'cilSettings',
    to: '/base/SuperAdmin',
      
  },
  

]
