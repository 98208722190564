<template>
<CHeader position="sticky" class="p-0 mb-2 bg-light text-dark">
    <CContainer fluid class="p-3 mb-2 bg-light text-dark">
        <CHeaderToggler class="sideColor text-white" @click="$store.commit('toggleSidebar')">
            <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>

        <CHeaderBrand class="mx-auto d-lg-none" to="/">
            <img src="@/assets/images/avatars/111.png" class="" width="80 " height="70" />
        </CHeaderBrand>

        <CHeaderNav>
            <AppHeaderDropdownAccnt />
            <CNavItem style="margin-top:10px" class="fw-bold">
                <div>
                     <buttom type="button" @click.prevent="logOut()" style="text-decoration: none; color: rgb(44 56 74 / 86%)">
                        <i class="fa fa-sign-out" aria-hidden="true"></i> Logout </buttom>
                </div>
            </CNavItem>
        </CHeaderNav>
    </CContainer>
</CHeader>
</template>

<script>
import axios from 'axios'
// import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import {
    logo
} from '@/assets/brand/logo'
export default {
    name: 'AppHeader',
    components: {
        // AppBreadcrumb,
        AppHeaderDropdownAccnt,
    },
    setup() {
        return {
            logo,
            list:[]
        }
    },
    async mounted() {
       
       const body = {
           admin_id: JSON.parse(localStorage.getItem('admin_id'))
       }
       const Headers = {
           "Content-type": "application/json; charset=UTF-8",
           "Authorization": JSON.parse(localStorage.getItem('admin_id'))
       }

       let result = await axios.post('adm-details-byId', body,  {
           headers: Headers
       })
      // console.log("resultdata",result)
       this.list = result.data.Data
      //  this.adminName = this.list[0].name
      //  this.adminId=body

      //  console.log('this  list', this.adminId)
   },

    methods: {
        logOut() {
            // console.log('sdsffddf')
            localStorage.removeItem('admin_id')
             localStorage.removeItem('admin_type')
             localStorage.removeItem('name')
            this.$router.push({
                name: 'Login'
            })
        },
    },
}
</script>
<style scoped>
.sideColor{
    background: linear-gradient(45deg, rgb(211, 86, 164) 0%, rgb(84, 165, 221) 100%) !important;
}

</style>