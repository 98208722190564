<template>
  <CFooter>
    <div class="mx-auto">
      <a target="_blank"><b>Copyright</b> StudioThink Admin Panel</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div>
   
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
